<template>
  <a
    v-if="tracking"
    target="_blank"
    :href="`/administration/receipt-history?goods_receipt_tracking=${tracking}`"
  >
    {{ tracking }}
  </a>
</template>

<script>
export default {
  name: "ReceiptTracking",
  props: {
    tracking: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped></style>
